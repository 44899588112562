export const config = {
  mode: process.env.NODE_ENV || 'development',
  appName: process.env.REACT_APP_NAME || 'Viet An Travel',
  apiUrl: process.env.REACT_APP_API_URL,
} as const

export const ENDPOINT = {
  LOGIN: '/Login/LoginEco',
  GET_MENU: '/Login/GetMenu',

  GET_ALL_CATEGORIES : '/TourCategory/GetAll',
  SEARCH_CATEGORY: '/TourCategory/Search',
  CREATE_CATEGORY: '/TourCategory/Create',
  UPDATE_CATEGORY: '/TourCategory/Update',
  DELETE_CATEGORY: '/TourCategory/Delete',

  GET_ALL_DESTINATIONS: '/DestinationCategory/GetAll',
  GET_DESTINATION_BY_TOUR_ID: '/TourDestination/GetAllByTourId',

  GET_ALL_TOURS: '/Tour/GetAll',
  GET_TOUR_BY_ID: '/Tour/GetById',
  SEARCH_TOUR: '/Tour/Search',  
  CREATE_TOUR: '/Tour/Create',
  UPDATE_TOUR: '/Tour/Update',
  DELETE_TOUR: '/Tour/Delete',
  DUPLICATE_TOUR: '/Tour/Duplicate',

  UPLOAD_IMG: '/TourImage/UploadImageAsync',
  GET_IMG: '/TourImage/GetImageAsync',
  DELETE_IMG: '/TourImage/DeleteImageAsync',

  GET_TOUR_DETAIL_BY_TOUR_ID: '/TourDetail/GetByTourId',

  SEARCH_DISCOUNT:'/TourCategoryDiscount/Search',
  GET_DISCOUNT_BY_TOUR_CATEGORY_ID:'/TourCategoryDiscount/GetByTourCategoryId',
  CREATE_DISCOUNT:'/TourCategoryDiscount/Create',
  UPDATE_DISCOUNT:'/TourCategoryDiscount/Update',
  DELETE_DISCOUNT:'/TourCategoryDiscount/Delete',
  
  GET_WARN_BY_ID: 'Warn/FindById',
  CREATE_WARN : '/Warn/Create',
  UPDATE_WARN : '/Warn/Update',
  GET_ALL_WARN : '/Warn/GetAll',
  DELETE_WARN : '/Warn/Delete',
  SEARCH_WARN: '/Warn/Search',

  CREATE_PAYMENT_SETTINGS : 'PaymentSettings/Create',
  UPDATE_PAYMENT_SETTINGS : 'PaymentSettings/Update',
  DELETE_PAYMENT_SETTINGS : 'PaymentSettings/Delete',
  GET_ALL_PAYMENT_SETTINGS : 'PaymentSettings/GetAll',
  GET_PAYMENT_SETTINGS_BY_ID : 'PaymentSettings/GetById',

  ToursSearchInBooking : '/Tour/SearchInBooking',
  DestinationCategory :'/DestinationCategory/GetAll',

  CREATE_BOOKING :'/Booking/Create',
  SEARCH_BOOKING :'/Booking/Search',
  GET_BOOKING_BY_ID :'/Booking/GetById',

  CREATE_BOOKING_TRANSACTION : '/Booking/Transaction/Create',
} as const

// quill toolbar options
export const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  [{ list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  ['clean'],
];