import { AuthLayoutFooter, LinkTag, FooterElement, FooterSpacing } from './AuthLayoutStyled';
import { CopyrightOutlined } from '@ant-design/icons';

const AuthFooter = () => {
  return (
    <AuthLayoutFooter>
      <FooterElement>
        Viet An Travel  
        <FooterSpacing><CopyrightOutlined /></FooterSpacing>
        2023
      </FooterElement>
      <FooterElement>
        <LinkTag href="https://787.vn">VietAn</LinkTag>
      </FooterElement>
      <FooterElement>
        <LinkTag>Liên hệ</LinkTag>
      </FooterElement>
      <FooterElement>
        <LinkTag>Về chúng tôi</LinkTag>
      </FooterElement>

    </AuthLayoutFooter>
  );
};

export default AuthFooter;
