import { Suspense, useCallback, useEffect } from 'react';
import { useStore } from '@/store';
import AppRoutes from '@routes/AppRoutes';
import './global.scss';
import 'antd/dist/reset.css';
import { ConfigProvider } from 'antd';
import vi_VN from 'antd/locale/vi_VN';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import utc from 'dayjs/plugin/utc';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

dayjs.extend(utc);
dayjs.utc();

const App = () => {
  const { commonStore } = useStore();
  const { setTheme } = commonStore;

  const appInitializer = useCallback(() => {
    const currentTheme = localStorage.getItem('appTheme');
    setTheme(currentTheme || '#997e15');
  }, [setTheme]);

  useEffect(() => {
    appInitializer();
  }, [appInitializer]);

  return (
    <Suspense
      fallback={
        <Spin
          indicator={antIcon}
          style={{
            fontSize: '48px',
            color: '#997e15',
            position: 'fixed',
            top: 'calc(50% - 40px)',
            left: 'calc(50% - 40px)',
          }}
        />
      }>
      <ConfigProvider
        locale={vi_VN}
        theme={{
          token: {
            colorPrimary: commonStore.appTheme,
            borderRadius: 6,
          },
        }}>
        <AppRoutes />
      </ConfigProvider>
    </Suspense>
  );
};

export default App;
