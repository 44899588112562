import { IDestination } from 'AppModels';
import { action, observable } from 'mobx';

export class TourStore {
    @observable pointsList : IDestination[] = [];
    @action setPointsList = (pointsList: IDestination[]) => {
        this.pointsList = pointsList;
    }
}

export default new TourStore();