import { ENDPOINT } from "@/config";
import httpClient from "@/services/httpClient";
import { TAuthenticationPayload, TLoginResponse } from "AppModels";

export const LoginApi = async (payload: TAuthenticationPayload) => {
    return await httpClient<TLoginResponse>({
        url: ENDPOINT.LOGIN,
        method: 'post',
        data: payload
    })
}